<template>
  <el-dialog
    custom-class="submit-success-dialog"
    :visible="submitSuccesVisible"
    width="350px"
    :show-close="false"
    destroy-on-close
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <template #title>
      <div class="dialog-title-wrapper">
        <i
          class="iconfont icon-v_close-large close-btn"
          @click="handleClose"
        />
      </div>
    </template>
    <div class="body-wrapper">
      <div class="success-img">
        <img
          src="./reset_success@2x.png"
        >
      </div>
      <p class="success-msg">
        恭喜您，提交成功！
      </p>
      <p class="success-desc">
        您发起的直播申请已提交至后台人工审核，预计将于1个工作日内反馈，请注意查收相关消息。
      </p>
      <div class="back-home">
        <span
          class="btn"
          @click="backHome"
        >返回首页({{ second }}s)</span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: '',
  props: {
    submitSuccesVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      second: 5,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.second--;
      if (this.second === 0) {
        this.backHome();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    backHome() {
      this.handleClose();
      this.$router.push('/home');
    },
  },
};
</script>
<style lang="scss" scoped>

.dialog-title-wrapper {
  overflow: hidden;
  height: 32px;
  padding-top: 16px;
  line-height: 16px;

  .close-btn {
    float: right;
    cursor: pointer;
    color: #9699ac;

    &:hover {
      color: #3e74ca;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 0 24px 40px 24px;
}

.success-msg {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333b44;
  line-height: 22px;
  text-align: center;
  margin-top: 30px;
}

.success-desc {
  font-size: 14px;
  color: #666b71;
  line-height: 20px;
  margin-top: 16px;
}

.success-img {
  text-align: center;

  img {
    width: 72px;
    height: 72px;
  }
}

.back-home {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .btn {
    width: 110px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #e4e5eb;
    font-size: 14px;
    border-radius: 4px;
    color: #333b44;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.submit-success-dialog {
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);

  &.el-dialog .el-dialog__header {
    border-bottom: none;
    padding: 0 24px;
  }
}
</style>
